<script>
  import Layout from "../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import appConfig from "../../../app.config";
import axios from 'axios';
import moment from "moment";
import LoadingVue from "./components/Loading.vue";
import Swal from "sweetalert2";
  export default {
    page: {
      title: "Transaction History",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        link: process.env.VUE_APP_URL,
        title: "Transaction History",
        items: [{
            text: "Tables",
            href: "/",
          },
          {
            text: "Transaction History",
            active: true,
          },
        ],
        load: false,
        data: [
        ],
        page: 1,
        perPage: 10,
        pages: [],
      };
    },
    name: "Widgets",
    components: {
      Layout,
      PageHeader,
      LoadingVue,
    },
    computed: {
      
      displayedPosts() {
        return this.paginate(this.data);
      },
      resultQuery() {
        if (this.searchQuery) {
          const search = this.searchQuery.toLowerCase();
          return this.displayedPosts.filter((data) => {
            return (
              data.id.toLowerCase().includes(search) ||
              data.name.toLowerCase().includes(search) ||
              data.email.toLowerCase().includes(search) ||
              data.position.toLowerCase().includes(search) ||
              data.company.toLowerCase().includes(search) ||
              data.country.toLowerCase().includes(search)
            );
          });
        } else {
          return this.displayedPosts;
        }
      },
    },
    watch: {
      posts() {
        this.setPages();
      },
    },
    created() {
        this.gettrans();
      
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    methods: {
          redirect(hash){
           this.$router.push({name: "transaction", params: { hash: hash}});
        
        },
            dateTime(value) {
      return moment(value).fromNow();
    },
          async gettrans(){
    
               this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/transactions").catch((error) => {

this.load = false;
                Swal.fire("Error!", "Please Check Your Internet Connection", "error");
                throw error;

            }).then((response) => {
this.load = false;
                if (response.data.status == true) {

                    this.data = response.data.data.data;
                    this.setPages();


                }

            })
        },
      setPages() {
        let numberOfPages = Math.ceil(this.data.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(data) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return data.slice(from, to);
      },
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]" />
        <loading-vue v-if="load"></loading-vue>
    <div class="row" v-else>
      <div class="card card-body">
        <div class="card-body">
          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" data-sort="currency_name" scope="col">   Title</th>
                  <th class="sort" data-sort="current_value" scope="col">
                Orderid
                  </th>
                  <th class="sort" data-sort="pairs" scope="col">Recipient</th>
                  <th class="sort" data-sort="high" scope="col">Status</th>
                  <th class="sort" data-sort="low" scope="col">Data</th>
                  
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td class="id">

                    <b-img :src="link + data.image" alt="" class="rounded-circle avatar-xs"></b-img>
                   <span class="ms-2"><strong> {{ data.title }}</strong></span>
                  </td>
                  <td>{{ data.code }}</td>
                  <td class="pairs">{{ data.recipient }}</td>
                  <td class="high"><b-badge v-if="data.status == 1" variant="warning" class="badge-label" ><i class="mdi mdi-circle-medium"></i> pending</b-badge>


<b-badge v-else-if="data.status == 2" variant="primary" class="badge-label" ><i class="mdi mdi-circle-medium"></i> processing</b-badge>



<b-badge v-else-if="data.status == 3" variant="success" class="badge-label" ><i class="mdi mdi-circle-medium"></i> completed</b-badge>

<b-badge v-else-if="data.status == 0" variant="danger" class="badge-label" ><i class="mdi mdi-circle-medium"></i> cancelled</b-badge>
</td>
                  <td class="low">{{ dateTime(data.created_at) }}</td>
                
                  <td>
                    <span><b-button variant="primary" size="sm" @click="redirect(data.hash)">view</b-button>
                    
            
                    </span>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li :class="{
                              active: pageNumber == page,
                              disabled: pageNumber == '...',
                            }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                  @click="page = pageNumber">
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                Next
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>